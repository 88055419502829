import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { sendData } from "./FilterSubCom";

const Users = () => {
  const [dataArr, setDataArr] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [Search, setSearch] = useState("");
  const [SearchBY, setSearchBY] = useState("Email");
  const [AllChecked, setAllChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let ln = localStorage.getItem("loggedin");
    if (ln == null) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: "get",
        url: "https://api.db2sg.com/users",
        headers: {},
      };
      try {
        const response = await axios.request(config);
        let resData = response.data;
        resData.forEach((element) => {
          element.checked = false;
        });
        console.log(resData);
        setDataArr(resData);
        setFilteredData(resData); // Set initial filtered data to all data
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const [anyUserSelected, setanyUserSelected] = useState(false);
  useEffect(() => {
    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].checked) {
        return setanyUserSelected(true);
      }
    }
    setanyUserSelected(false);
  }, [filteredData]);

  useEffect(() => {
    const lowercasedSearch = Search.toLowerCase();
    if (lowercasedSearch != "") {
      const filtered = dataArr.filter((item) => {
        if (SearchBY === "Email") {
          return item.email?.toLowerCase().includes(lowercasedSearch);
        } else if (SearchBY === "PhoneNumber") {
          return item.phoneNumber?.toLowerCase().includes(lowercasedSearch);
        } else if (SearchBY === "Organization") {
          return item.orgName?.toLowerCase().includes(lowercasedSearch);
        } else if (SearchBY === "Name") {
          return item.name?.toLowerCase().includes(lowercasedSearch);
        }
        return false;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(dataArr);
    }
  }, [Search, SearchBY, dataArr]);

  const [notificationopen, setnotificationOpen] = useState(false);

  const handlenotificationOpen = () => {
    setnotificationOpen(true);
  };

  const handlenotificationClose = () => {
    setnotificationOpen(false);
  };

  const handlenotificationSubmit = ({ title, body }) => {
    console.log("Form data:", { title, body });
    // Handle form submission logic here
    let arr = [];
    filteredData.forEach((e) => {
      if (e.checked) {
        arr.push(e.fcmToken);
        sendData(
          "user/unotification",
          JSON.stringify({ token: e.fcmToken, title: title, body: body })
        );
      }
    });
  };

  const [Otaopen, setOtaOpen] = useState(false);


  const handleOtaOpen = () => {
    setOtaOpen(true);
  };

  const handleOtaClose = () => {
    setOtaOpen(false);
  };

  const handleOtaSubmit = ({ title, body }) => {
    console.log("Form data:", { title, body });
    // Handle form submission logic here
    let arr = [];
    filteredData.forEach((e) => {
      if (e.checked) {
        arr.push(e.fcmToken);
        sendData(
          "user/unotification",
          JSON.stringify({ token: e.fcmToken, title: title, body: body })
        );
      }
    });
  };
  return (
    <div>
      <Box className="p-10">
        <NotificationForm
          open={notificationopen}
          onClose={handlenotificationClose}
          onSubmit={handlenotificationSubmit}
        />
        <FileUploadDialog
          open={Otaopen}
          userIds={filteredData.filter((e) => e.checked)}
          onClose={handleOtaClose}
        />
        <TextField
          value={Search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          sx={{ width: "50%" }}
          label="Search"
          variant="outlined"
        />
        <FormControl sx={{ width: "20%" }}>
          <InputLabel id="demo-simple-select-label">Search By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={SearchBY}
            label="Search by"
            onChange={(e) => {
              setSearchBY(e.target.value);
            }}
          >
            <MenuItem value={"Name"}>Name</MenuItem>
            <MenuItem value={"PhoneNumber"}>Phone Number</MenuItem>
            <MenuItem value={"Email"}>Email</MenuItem>
            <MenuItem value={"Organization"}>Organization name</MenuItem>
          </Select>
        </FormControl>
        <Button
          onClick={() => {
            localStorage.removeItem("loggedin");
            navigate("/");
          }}
        >
          Logout
        </Button>
      </Box>
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            color: (theme) => theme.palette.primary.text,
            mt: "15px",
            fontWeight: "600",
            fontSize: "20px",
          }}
        >
          Showing available Users
        </Box>
        <div>
          <Button
            disabled={!anyUserSelected}
            onClick={() => {
              handleOtaOpen();
            }}
          >
            Publish Ota
          </Button>
          <Button
            disabled={!anyUserSelected}
            onClick={() => {
              handlenotificationOpen();
            }}
          >
            Notification
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="custom table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#E9F0F4" }}>
              <TableCell>
                <Checkbox
                  checked={AllChecked}
                  onChange={(e) => {
                    setAllChecked(e.target.checked);
                    const tempCopy = filteredData.map((element) => ({
                      ...element,
                      checked: e.target.checked,
                    }));
                    setFilteredData(tempCopy);
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography variant="h6">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Phone Number</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Organization Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Devices</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredData.map((el, index) => (
              <TableRow
                key={index}
                sx={{
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f5f5f5" }, // Optional hover effect
                }}
                onClick={() => {
                  navigate("/home", { state: el });
                  console.log(el);
                }}
              >
                <TableCell>
                  <Checkbox
                    checked={el.checked}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setFilteredData((temp) => {
                        const newTemp = [...temp];
                        newTemp[index].checked = e.target.checked;
                        return newTemp;
                      });
                    }}
                  />
                </TableCell>
                <TableCell>{el?.name ? el.name : el.esp_id}</TableCell>
                <TableCell>{el?.email}</TableCell>
                <TableCell>{el?.phoneNumber}</TableCell>
                <TableCell>{el?.orgName}</TableCell>
                <TableCell>
                  {el.devices != undefined
                    ? Object.keys(el?.devices).length
                    : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Users;

const NotificationForm = ({ open, onClose, onSubmit }) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ title, body });
    onClose(); // Close the modal after submission
    setTitle("");
    setBody("");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Submit Your Details</DialogTitle>
      <DialogContent>
        <div className="mb-4 p-2">
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mb-4 "
          />
        </div>
        <div className="mb-4">
          <TextField
            label="Body"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{onClose(); setTitle("");setBody("");}} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FileUploadDialog = ({ open, onClose, userIds }) => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    let  fi = event.target.files[0]
    if (fi.name.split(".").pop() !== "bin") {
      alert("Please upload a valid .bin file");
      return;
    }
    setFile(fi);
    setTitle(fi.name.split(".bin")[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!file || !title) {
      alert("The selected file is not a valid .bin file");
      setLoading(false);

      return;
    };
    let arr = [];
    userIds.forEach((e) => {
      let b = e.devices;
      if (b !== undefined) {
        Object.keys(e.devices).forEach((ele) => {
          arr.push(ele);
        });
      }
    });
    console.log(arr);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", title);
    formData.append("userIds", JSON.stringify(userIds.map((e) => e.id)));
    formData.append("deviceIds", JSON.stringify(arr));
    try {
      await axios.post("https://api.db2sg.com/ota", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("File uploaded successfully");
      setFile(null);
      setTitle("");
      onClose();
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <div className="mb-4">
          <TextField
            variant="outlined"
            fullWidth
            value={file?.name.split(".bin")[0]}
            className="mb-4"
          />
        </div>
        <div className="mb-4">
          <input type="file" onChange={handleFileChange} className="mb-4" />
        </div>
      </DialogContent>
      <DialogActions>
         <Button onClick={()=>{onClose();setTitle("")}} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
         { loading ? <CircularProgress size={20}></CircularProgress> : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
