import { useState, useEffect } from 'react';
import tz_lookup from 'tz-lookup';



const LocationTime = ({latitude,longitude, setUserTimeZone}) => {
    const [error, setError] = useState('');


    console.log("in the nagivation",latitude,longitude);

   
    useEffect(() => {
      // Get location and timezone
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          try {
             
            console.log("position is: ",position)

            console.log("time zone now",tz_lookup(position.coords.latitude, position.coords.longitude));

            setUserTimeZone(tz_lookup(position.coords.latitude,position.coords.longitude));
          }
           catch (err) {
            setError('Error fetching timezone');
          }
        }, () => {
          setError('Location access denied');
        });
      }
    }, []);
  };
  
  

export default LocationTime;