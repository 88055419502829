import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapContainer = (props) => {
  const { lat, lng, maxHeight, maxWidth } = props;

  const mapStyles = {
    width: maxWidth || '100%',
    height: maxHeight || '100%',
  };

  return (
    <div style={mapStyles}>
      <Map
        google={props.google}
        zoom={14}
        initialCenter={{ lat, lng }}
        center={{ lat, lng }}
        containerStyle={mapStyles}
      >
        <Marker position={{ lat, lng }} />
      </Map>
    </div>
  );
};
export const getAddressFromLatLng = async (lat, lng) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBajhVyi8KbuKuEPk1osx9M_7qmpg8e1-c`);
    const data = await response.json();
    if (data.status === 'OK') {
      const address = data.results[0].formatted_address;
      return address;
    } else {
        console.log(data.status);
    }
  };
  


export default GoogleApiWrapper({
  apiKey: "AIzaSyBajhVyi8KbuKuEPk1osx9M_7qmpg8e1-c",
})(MapContainer);
