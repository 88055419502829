import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "tailwindcss/tailwind.css";

const OTAList = () => {
  const [otas, setOtas] = useState([]);

  useEffect(() => {
    const fetchOtas = async () => {
      try {
        const item =localStorage.getItem("otaHistory")
        if (item != undefined){
          setOtas(JSON.parse(item))
        }
        const response = await axios.get("https://api.db2sg.com/ota/history");
        setOtas(response.data);
        localStorage.setItem("otaHistory",JSON.stringify(response.data))
      } catch (error) {
        console.error("Error fetching OTAs:", error);
      }
    };

    fetchOtas();
  }, []);

  const getDate = ({ _seconds, _nanoseconds }) => {
    const timestamp = (_seconds * 1000) + (_nanoseconds / 1000000);
    const date = new Date(timestamp);
    return date
  }

  const formatTimestamp = ({ _seconds, _nanoseconds }) => {
    const timestamp = (_seconds * 1000) + (_nanoseconds / 1000000);
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    });
  };

  return (
    <Container className="mt-8">
      {otas.length == 0 && <CircularProgress />}
      {otas.sort((a, b) => new Date(getDate(b.createdAt)) - new Date(getDate(a.createdAt))).map((ota) => (
        <Card key={ota.otaId} className="mb-4 rounded-3xl border-solid border-8 ">
          <CardContent>
            <Typography variant="h5" component="div">
              {ota.versionNo}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              File URL:{" "}
              <a href={ota.fileUrl} className="text-blue-800 font-bold"  target="_blank" rel="noopener noreferrer">
                {ota.versionNo} url 🔗
              </a>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Created At:{" "}
              {formatTimestamp(ota.createdAt)}
            </Typography>
            <Accordion className="mt-4">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Users</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="user table">
                    <TableHead>
                      <TableRow sx={{ background: "#E9F0F4" }}>
                        <TableCell>
                          <Typography variant="h6">Name</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">Email</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">Phone Number</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">
                            Organization Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6">Devices</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ota.users.map((el) => (
                        <TableRow
                          key={el.id}
                          sx={{
                            "&:nth-of-type(odd)": {
                              backgroundColor: "#F9FAFB",
                            }, // Alternate row color
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {el?.name ? el.name : el.esp_id}
                          </TableCell>
                          <TableCell>{el?.email}</TableCell>
                          <TableCell>{el?.phoneNumber}</TableCell>
                          <TableCell>{el?.orgName}</TableCell>
                          <TableCell>
                            {el.devices !== undefined
                              ? Object.keys(el?.devices).length
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
};

export default OTAList;
