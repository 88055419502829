const male = {
  "01:00T01:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "03:00T03:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "04:00T04:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "05:00T05:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "06:00T06:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "07:00T07:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "08:00T08:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "09:00T09:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "10:00T10:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "11:30T12:00": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "13:00T13:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "15:00T15:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "17:30T17:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "19:00T19:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "21:00T21:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
  "23:30T23:30": { status: true, days: [0, 1, 2, 3, 4, 5, 6] },
};

const female = {
  "00:00T02:00": {
    status: true,
    days: [0, 1, 2, 3, 4, 5, 6],
  },
  "08:00T10:00": {
    status: true,
    days: [0, 1, 2, 3, 4, 5, 6],
  },
  "15:00T17:00": {
    status: true,
    days: [0, 1, 2, 3, 4, 5, 6],
  },
};

export { male, female };
