import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, TableContainer, Paper } from "@mui/material";
import Users from "./Users"; // Assuming your Users component is in Users.js
import OTAList from "./OtaHistory"; // Assuming your OTAList component is in OTAList.js

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export default function UserOTATabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="User and OTA tabs">
          <Tab label="Users" {...a11yProps(0)} />
          <Tab label="OTA List" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {/* Tab Panels for Users and OTAList */}
      <TabPanel value={value} index={0}>
        {/* Users Component */}
        <TableContainer component={Paper}>
          <Users />
        </TableContainer>
      </TabPanel>

      <TabPanel value={value} index={1}>
        {/* OTAList Component */}
        <TableContainer component={Paper}>
          <OTAList />
        </TableContainer>
      </TabPanel>
    </Box>
  );
}
