import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { HomeIcon } from "../../utils/Icons/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { client } from "./Socket";
import axios from "axios";

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let ln = localStorage.getItem("loggedin")
    if(ln == null){
      navigate("/")
    }
  }, []);

  const [dataArr, setDataArr] = useState([]);
  useEffect(() => {
    console.log(location.state);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.db2sg.com/esps",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        let resData = response.data;
        if (resData.data) {
          // const filteredData = resData.data.filter((esp) => {
          //   return esp.esp_id in location.state.devices;
          // });
          let x= {...location.state.devices}
          console.log(x)
          let arr = [];
           Object.keys(x).map((e)=>{
            arr.push({esp_id:e,...x[e]})
           })
          
          setDataArr(arr);

          // setDataArr(location.state.devices);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* <button
        onClick={() => {
          client.publish("time_left", `Hello mqtt ${Date.now()}`);
        }}
      >
        {" "}
        pulish
      </button> */}
      {/* <TimeBox /> */}

      <Box
        sx={{
          color: (theme) => theme.palette.primary.text,
          mt: "15px",
          fontWeight: "600",
          fontSize: "20px",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/users");
          }}
        >
          Users{" "}
        </span>{" "}
        {">"}
        Showing available Devices
      </Box>
      {dataArr?.map((el, index) => (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 9fr",
            mt: "25px",
            gridGap: "1px",
            padding: "10px 2px 10px 2px",
            background: "#E9F0F4",
            height: "80px",
            borderBottom: "1px solid #CECFD2",
            cursor: "pointer",

            justifyContent: "center",
            alignContent: "center",
          }}
          onClick={() => {
            const schedules = location.state
            console.log("ela",el);
            if (el.provisioned){
              return navigate("/home/filter", {
                state: { ...el, user: location.state },
              });
            }
            alert("device not provisioned: First provision it from the app")
          }}
        >
          <Box sx={{ justifyItems: "center", textAlign: "center" }}>
            {" "}
            <HomeIcon />
          </Box>
          <Box>
            {" "}
            <Typography variant="h6">
            {el?.deviceName ? el.deviceName : ""} {el?.name ? el.name : el.esp_id}{" "}{el.provisioned?"✅":"❌"}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}
