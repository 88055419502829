import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Outlet,
  Navigate,
} from "react-router-dom";

// import "./App.css";

// Import toastify css file

import Home from "./component/Home/Home";
import FilterController from "./component/Home/FilterController";
import Schedule from "./component/Home/Schedule";
import { Login } from "./component/Home/Login";
import Users from "./component/Home/Users";
import OTAList from "./component/Home/OtaHistory.jsx";
import TabPanel from "./component/Home/Tabs.jsx"
const App = () => {
  const [cookies, setCookies] = useState("");

  const [messagingToken, setMessagingToken] = useState(null);

  return (
    <div className="App">
      {/* notification  */}
      {/* <ToastContainer /> */}

      {/* <NotificationContainer style={{ zIndex: 100 }} /> */}

      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/users" element={<TabPanel />} />
          <Route path="/otaHistory" element={<OTAList />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/filter" element={<FilterController />} />
          <Route path="/home/filter/schedule" element={<Schedule />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
