import { Box, Button, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
export default function TimeBox({
  data,
  setTimeBoxPopUp,
  setTimeEditData,
  setTimeArr,
  timeArr,
}) {
  const [dayIndex, setDayIndex] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thur",
    "Fri",
    "Sat",
  ]);

  const deleteFun = (time) => {
    setTimeArr((prev) => prev.filter((el) => el.time != time));
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          background: "#E9F0F4",
          borderRadius: "6px",
          flexDirection: "column",
          justifyItems: "center",
          padding: "10px",
          margin: "auto",
          gap: "4px",
          height: "170px",
          width: "380px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 2fr",
          }}
        >
          {" "}
          <Box>
            {" "}
            <Typography
              variant="h6"
              style={{
                color: "#5D5F63",
              }}
            >
              {data?.time?.split("T")[0]}

              {/* {
                    > 0
                    ? `${data?.time?.split("T")[0].split(":")[0] / 12} PM`
                    : `${data?.time?.split("T")[0].split(":")[0] / 12} AM`
                } */}
            </Typography>
          </Box>{" "}
          <Box>
            {" "}
            <Typography
              variant="h6"
              style={{
                color: "#A6A8AD",
              }}
            >
              {" "}
              to
            </Typography>
          </Box>{" "}
          <Box>
            {" "}
            <Typography
              variant="h6"
              style={{
                color: "#5D5F63",
              }}
            >
              {" "}
              {data?.time?.split("T")[1]}
            </Typography>
          </Box>{" "}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "5fr 1fr",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            {" "}
            <Typography
              variant="level"
              sx={{
                color: "#5D5F63",
              }}
            >
              {data?.days?.map((el, index) => {
                return `${dayIndex[el]} ${
                  index == data?.days?.length - 1 ? "" : ","
                }`;
              })}
            </Typography>{" "}
          </Box>

          <Box>
            <Switch
              checked={data?.status}
              onChange={(e) => {}}
              name="loading"
              color="primary"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "22px",
          }}
        >
          {["S", "M", "T", "W", "T", "F", "S"]?.map((el, index) => (
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                cursor: "pointer",
                border: "2px solid",
                borderColor:
                  data?.days.indexOf(index) > -1 ? "blue" : "#FFFFFF",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                background: "#FFFFFF",
              }}
            >
              {el}
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              deleteFun(data.time);
            }}
            sx={{
              height: "30px",
              mt: "5px",
              color: "#5D5F63",
            }}
          >
            <Typography variant="body2">Delete </Typography>
          </Button>

          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{
              height: "30px",
              mt: "5px",
              color: "#5D5F63",
            }}
            onClick={() => {
              setTimeBoxPopUp(true);
              setTimeEditData(data);
            }}
          >
            <Typography variant="body2">Edit </Typography>
          </Button>
        </Box>
      </Box>
    </div>
  );
}
