const data = [
  {
    name: "Time Zone",
    status: "",
  },
  {
    name: "System ON LED",
    status: "system_led",
  },

  {
    name: "Filter ON LED",
    status: "filter_led",
  },

  {
    name: "No Water LED",
    status: "water_led",
  },
  {
    name: "High Current/Filter Blocked LED",
    status: "blocked_led",
  },

  
];

export { data };
