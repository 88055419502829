import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import { Box, Button, Switch, Typography } from "@mui/material";

const AddScoring = (props) => {
  const { onClose, open, timeEditData, setTimeArr, timeArr } = props;
  const [time, setTime] = useState({
    toh: "",
    tom: "",
    fromh: "",
    fromm: "",
  });
  const [switchButt, setSwitchButt] = useState(false);
  const [dayIndex, setDayIndex] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thur",
    "Fri",
    "Sat",
  ]);

  const [userDay, setUserDay] = useState({});
  const handleClose = () => {
    setTime({
      toh: "",
      tom: "",
      fromh: "",
      fromm: "",
    });
    setSwitchButt(false);
    setUserDay({});
    onClose();
  };
  const formatTime = (time) => {
    const padZero = (num) => (num < 10 ? `0${num}` : num);
  
    const parseAndClamp = (value, min, max) => {
      const parsed = parseInt(value) || 0;
      return Math.max(min, Math.min(max, parsed));
    };
  
    const fromh = parseAndClamp(time.fromh, 0, 23);
    const fromm = parseAndClamp(time.fromm, 0, 59);
    const toh = parseAndClamp(time.toh, 0, 23);
    const tom = parseAndClamp(time.tom, 0, 59);
  
    return `${padZero(fromh)}:${padZero(fromm)}T${padZero(toh)}:${padZero(tom)}`;
  };
  
  useEffect(() => {
    if (timeEditData?.time) {
      console.log(timeEditData);
      setTime({
        fromh: timeEditData?.time?.split("T")[0].split(":")[0],
        fromm: timeEditData?.time?.split("T")[0].split(":")[1],
        toh: timeEditData?.time?.split("T")[1].split(":")[0],
        tom: timeEditData?.time?.split("T")[1].split(":")[1],
      });

      setSwitchButt(timeEditData?.status);

      console.log(timeEditData?.days);
      timeEditData?.days?.map((el) => {
        setUserDay((prev) => ({ ...prev, [String(el)]: el }));
      });

    }
  }, [timeEditData]);

  const saveForm = async () => {
    const timekey = formatTime(time);
    const days = Object.keys(userDay).map(Number);
    const newTimeEntry = {
      time: timekey,
      days,
      status: switchButt,
    };
  
    if (timeEditData?.time) {
      setTimeArr(prevTimeArr =>
        prevTimeArr.map(el =>
          el.time === timeEditData.time ? newTimeEntry : el
        )
      );
    } else {
      setTimeArr(prevTimeArr => [...prevTimeArr, newTimeEntry]);
    }
  
    handleClose();
  };
  
  return (
    <Dialog
      fullWidth={false}
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "500px",
          width: "60vw",
          padding: "5px",
        },
      }}
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          height: "10px",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <button
          onClick={() => {
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div> Time</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  background: "#E9F0F4",
                  borderRadius: "6px",
                  flexDirection: "column",
                  justifyItems: "center",
                  padding: "10px",
                  margin: "auto",
                  gap: "20px",
                  height: "250px",
                  width: "380px",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "2fr 1fr 2fr",
                  }}
                >
                  {" "}
                  <Box>
                    {" "}
                    <Typography
                      variant="h6"
                      style={{
                        color: "#5D5F63",
                      }}
                    >
                      {" "}
                      <input
                        type="number"
                        placeholder="HH"
                        value={time.fromh}
                        onChange={(e) => {
                          setTime((prev) => ({
                            ...prev,
                            fromh: e.target.value,
                          }));
                        }}
                        max={"12"}
                        min={"00"}
                        style={{
                          outline: "none",
                          width: "100%",
                          border: "1px solid #D9D9D9",
                          height: "37px",
                          borderRadius: "6px",
                          color: "#1B1D1F",
                          fontWeight: "400",
                          fontSize: "14px",
                          padding: "2px 8px",
                          fontFamily: "Poppins,sans-serif",
                        }}
                      />
                      <input
                        type="number"
                        placeholder="MM"
                        value={time.fromm}
                        onChange={(e) => {
                          setTime((prev) => ({
                            ...prev,
                            fromm: e.target.value,
                          }));
                        }}
                        max={"59"}
                        min={"00"}
                        style={{
                          outline: "none",
                          width: "100%",
                          border: "1px solid #D9D9D9",
                          height: "37px",
                          borderRadius: "6px",
                          color: "#1B1D1F",
                          fontWeight: "400",
                          fontSize: "14px",
                          padding: "2px 8px",
                          fontFamily: "Poppins,sans-serif",
                        }}
                      />
                    </Typography>
                  </Box>{" "}
                  <Box>
                    {" "}
                    <Typography
                      variant="h6"
                      style={{
                        color: "#A6A8AD",
                      }}
                    >
                      {" "}
                      to
                    </Typography>
                  </Box>{" "}
                  <Box>
                    {" "}
                    <Typography
                      variant="h6"
                      style={{
                        color: "#5D5F63",
                      }}
                    >
                      {" "}
                      <input
                        type="number"
                        placeholder="HH"
                        value={time.toh}
                        onChange={(e) => {
                          setTime((prev) => ({ ...prev, toh: e.target.value }));
                        }}
                        max={"12"}
                        min={"00"}
                        style={{
                          outline: "none",
                          width: "100%",
                          border: "1px solid #D9D9D9",
                          height: "37px",
                          borderRadius: "6px",
                          color: "#1B1D1F",
                          fontWeight: "400",
                          fontSize: "14px",
                          padding: "2px 8px",
                          fontFamily: "Poppins,sans-serif",
                        }}
                      />
                      <input
                        type="number"
                        placeholder="MM"
                        max={"59"}
                        value={time.tom}
                        onChange={(e) => {
                          setTime((prev) => ({ ...prev, tom: e.target.value }));
                        }}
                        min={"00"}
                        style={{
                          outline: "none",
                          width: "100%",
                          border: "1px solid #D9D9D9",
                          height: "37px",
                          borderRadius: "6px",
                          color: "#1B1D1F",
                          fontWeight: "400",
                          fontSize: "14px",
                          padding: "2px 8px",
                          fontFamily: "Poppins,sans-serif",
                        }}
                      />
                    </Typography>
                  </Box>{" "}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "5fr 1fr",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {" "}
                    <Typography
                      variant="level"
                      sx={{
                        color: "#5D5F63",
                      }}
                    >
                      {Object.keys(userDay)?.map((el, ind) => {
                        if (dayIndex[el]) {
                          //   console.log(Object.keys(userDay));
                          return `${dayIndex[el]} ${
                            Object.keys(userDay).length - 1 == ind ? "" : ","
                          }`;
                        }
                      })}
                    </Typography>{" "}
                  </Box>

                  <Box>
                    <Switch
                      checked={switchButt}
                      onChange={(e) => {
                        setSwitchButt(!switchButt);
                      }}
                      name="loading"
                      color="primary"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "22px",
                  }}
                >
                  {["S", "M", "T", "W", "T", "F", "S"]?.map((el, index) => (
                    <Box
                      onClick={() => {
                        if (!userDay[index]) {
                          setUserDay((prev) => ({ ...prev, [index]: true }));
                        } else {
                          let temp = { ...userDay };
                          delete temp[index];
                          setUserDay(temp);
                        }
                      }}
                      sx={{
                        width: "200px",
                        height: "30px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        border: "2px solid",
                        borderColor: userDay[index] >= 0 ? "blue" : "#FFFFFF",
                        justifyContent: "center",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        background: "#FFFFFF",
                      }}
                    >
                      {el}
                    </Box>
                  ))}
                </Box>
              </Box>
            </div>
            <div
              style={{ display: "flex", gap: "50px", justifyContent: "right" }}
            >
              {" "}
              <Button
                variant="outlined"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={saveForm}
              >
                {" "}
                Save
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default AddScoring;
