import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Switch, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IconAdd } from "../../utils/Icons/Icons";
import TimeBox from "./TimeBox";
import axios from "axios";
import TimeModal from "./TimeModal";
import { client } from "./Socket";
import countryCodes from "country-codes-list";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { female, male } from "./MaleJson";
import loda from "lodash";

const myCountryCodesObject = countryCodes.customList(
  "countryNameEn",
  "+{countryCallingCode}"
);
export default function Schedule() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let ln = localStorage.getItem("loggedin");
    if (ln == null) {
      navigate("/");
    }
  }, []);
  // console.log(location.state);
  const [data, setData] = useState(null);
  const [countryArr, setCountryArr] = useState([
    {
      name: "dsg",
    },
  ]);
  const [alertPhoneNumber, setAlertPhoneNumber] = useState([]);
  const [genderState, setGenderState] = useState(null);
  const [timeBoxPopUp, setTimeBoxPopUp] = useState(false);
  const [timeArr, setTimeArr] = useState([]);
  const [extraStateForTime, setExtraStateForTime] = useState([]);
  const [defaultState, setDefaultState] = useState(false);
  const [timeEditData, setTimeEditData] = useState({});
  const [espIdFrom, setEspIdFrom] = useState(location.state?.esp_id);
  const [disableAll, setDisableAll] = useState(false);

  const checkIfDefault = useCallback((repitions) => {
    console.log("====================================");
    let rep = {};
    console.log(male, female, repitions, genderState);
    console.log("hello", loda.isEqual(repitions, male));
    console.log("====================================");
    if (loda.isEqual(rep, male) && (genderState == "1" || genderState == 1)) {
      return true;
    } else if (
      loda.isEqual(rep, female) &&
      (genderState == "0" || genderState == 0)
    ) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    setCountryArr([]);
    for (let key in myCountryCodesObject) {
      setCountryArr((prev) =>
        prev.concat({ name: `${key} ${myCountryCodesObject[key]}` })
      );
    }
    console.log("This is the state", location.state);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://api.db2sg.com/esps/schedule/${location.state.user.id}/${location.state?.esp_id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        let resData = response.data;
        if (resData.data) {
          setData(resData.data);
          setGenderState(resData.data.gender);
          console.log(resData.data);
          const alertPhoneNumber = resData.data.phone.map((el, index) => {
            return { phone: el, country: resData.data.country[index] };
          });
          setAlertPhoneNumber(alertPhoneNumber);
          setDefaultState(resData.data.default == "1");
          let tempArr = [];
          let tempdata = JSON.parse(resData.data.repetations);
          // console.log(tempdata);
          for (let key in tempdata) {
            let obj = { time: key, ...tempdata[key] };
            tempArr.push(obj);
          }
          setExtraStateForTime(tempArr);
          setTimeArr(tempArr);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    let subArr = ["phone", "gender", "default", "repetations"];

    let tempArr = subArr.map((el) => {
      // setLocalState((prev) => ({
      //   ...prev,
      //   [proData[0].esp_id + "/" + el]: proData[0][el],
      // }));

      return location.state?.esp_id + "/" + el;
    });
    client.subscribe(tempArr, (err) => {
      if (!err) {
        // client.publish("time_left", "Hello mqtt");
      } else {
        console.log(err);
      }
    });
  }, []);

  client.on("message", (topic, message) => {
    // message is Buffer
    if (topic.split("/")[1] == "repetations") {
      let tempArr = [];
      let tempdata = JSON.parse(message.toString());
      // console.log(tempdata);
      for (let key in tempdata) {
        let obj = { time: key, ...tempdata[key] };
        tempArr.push(obj);
      }
      setExtraStateForTime(tempArr);

      setTimeArr(tempArr);
    }
    // if (topic.split("/")[1] == "phone") {
    //   const phoneNumbers =  JSON.parse(message.toString())
    //   phoneNumbers.map((el, index) => {
    //     return { phone: el, country: location.state.user.country[index] };
    //   });
    // }
    // if (topic.split("/")[1] == "country") {
    //   const countryNumbers =  JSON.parse(message.toString())
    //   countryNumbers.map((el, index) => {
    //     return { phone: location.state.user.phone[index], country: el };
    //   });
    // })

    if (topic.split("/")[1] == "gender") {
      setGenderState(JSON.parse(message.toString()));
    }

    if (topic.split("/")[1] == "default") {
      setDefaultState(JSON.parse(message.toString()));
    } else {
      setData((prev) => ({
        ...prev,
        [topic.split("/")[1]]: message.toString(),
      }));
    }
    // setLocalState((prev) => ({ ...prev, [topic]: message.toString() }));
    console.log(topic);
    console.log(message.toString());
    // client.end();
  });

  const addNewNumber = () => {
    setAlertPhoneNumber((prev) => [...prev, { phone: "", country: "" }]);
  };

  const deleteNumber = (el) => {
    setAlertPhoneNumber((prev) => prev.filter((ele) => ele != el));
  };

  const saveFormData = () => {
    let isPhoneNumberNotVaild = false;

    if (alertPhoneNumber.length > 3) {
      alert("You can't add more than 3 phone numbers");
      return;
    }

    for (let i = 0; i < alertPhoneNumber.length; i++) {
      if (
        alertPhoneNumber[i].phone.length < 10 ||
        alertPhoneNumber[i].phone.length > 15
      ) {
        alert("Phone number is not valid");
        return;
      }
    }

    let subArr = ["phone", "gender", "default", "repetations"];

    let repetations = {};

    timeArr.map((el) => {
      repetations[el.time] = { days: el.days, status: el.status };
    });
    client.publish(
      location.state?.esp_id + "/repetations",
      `${JSON.stringify(repetations)}`
    );
    client.publish(location.state?.esp_id + "/gender", `${genderState}`);
    client.publish(
      location.state?.esp_id + "/default",
      `${JSON.stringify(defaultState ? 1 : 0)}`
    );

    client.publish(
      location.state?.esp_id + "/phone",
      `${JSON.stringify(alertPhoneNumber.map((el) => el.phone))}`
    );
    client.publish(
      location.state?.esp_id + "/country",
      `${JSON.stringify(alertPhoneNumber.map((el) => el.country))}`
    );

    // console.log(`${location.state?.esp_id}/${repetations}`));
    console.log(timeArr, alertPhoneNumber, genderState, defaultState);
    const updated_schedule = {
      alert: {
        device: location.state?.esp_id,
        isDefault: defaultState,
        isFemale: genderState == "0" ? true : false,
        mobNumber: alertPhoneNumber.map((el) => el.phone),
        country: alertPhoneNumber.map((el) => el.country),
        timeStamp: new Date().toLocaleString(),
      },
      schedules: timeArr,
    };
    console.log(updated_schedule);
    console.log("====================================");
    console.log(location.state);
    axios.post(
      `https://api.db2sg.com/esps/schedule/${location.state.user.id}/${location.state.esp_id}`,
      {
        schedule: updated_schedule,
      }
    );
    console.log("====================================");
    alert("Schedule saved successfully");

  };
  console.log(alertPhoneNumber);

  return (
    <>
      <Box
        sx={{
          color: (theme) => theme.palette.primary.text,
          mt: "15px",
          fontWeight: "600",
          fontSize: "20px",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/users");
          }}
        >
          Users{" "}
        </span>{" "}
        {">"}
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/home", { state: location.state.user });
          }}
        >
          Available devices{" "}
        </span>{" "}
        {">"}
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/home/filter", { state: location.state });
          }}
        >
          {location.state?.name ? location.state.name : location.state?.esp_id}
        </span>
        {">"} Schedule
      </Box>

      <Box
        sx={{
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            saveFormData();
            // navigate("/");
          }}
        >
          {" "}
          Save
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          mt: "20px",
          gridTemplateColumns: "2fr 1fr 2fr",
          gridGap: "20px",
        }}
      >
        <Box
          sx={{
            background: "#FFFFFF",
            borderRadius: "6px",
            padding: "13px",
            display: "flex",
            gridTemplateColumns: "1fr",
            flexDirection: "column",
            // gridGap: "10px",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              variant="level"
              sx={{
                color: "#5D5F63",
              }}
            >
              {" "}
              Alert phone number
            </Typography>
            {alertPhoneNumber?.map((el, index) => {
              if (el.phone == undefined) {
                return;
              }
              return (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    // border: "1px solid #D9D9D9",
                    height: "60px",
                    gap: "20px",
                    borderRadius: "6px",
                    padding: "2px 8px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // options={countryArr}
                    value={el.country + " " + el.phone.split("-")[0]}
                    onChange={(e, newValue) => {
                      if (newValue == undefined) return;

                      setAlertPhoneNumber((prev) => {
                        const newArr = [...prev];
                        newArr[index]["country"] = newValue
                          .split("+")[0]
                          .trim();
                        if(el.phone.split("-")[1]){
                          newArr[index]["phone"] =
                          "+" +
                          newValue.split("+")[1].trim() +
                          "-" 
                          +el.phone.split("-")[1] ;
                        }else {
                          newArr[index]["phone"] =
                          "+" +
                          newValue.split("+")[1].trim()
                        }
                        return newArr;
                      });
                    }}
                    sx={{
                      outline: "none",
                      width: "40%",
                      border: "0px solid #D9D9D9",
                      height: "50px",
                      marginLeft: "0px",

                      // borderRadius: "6px",
                      color: "#1B1D1F",
                      fontWeight: "400",
                      fontSize: "14px",

                      fontFamily: "Poppins,sans-serif",
                    }}
                    options={countryArr?.map((ele) => ele.name)}
                    renderInput={(params) => (
                      <TextField {...params} name="Country" />
                    )}
                  />
                  <input
                    type="text"
                    placeholder="Enter an alert phone number"
                    value={el.phone.split("-")[1]}
                    onChange={(e) => {
                    
                      setAlertPhoneNumber((prev) => {
                        const newArr = [...prev];
                        console.log(
                          "THIS IS THE PHONENUMBER",
                          e.target.value,
                          newArr[index]["phone"],
                          el.phone.split("-")[0] + e.target.value
                        );

                        newArr[index]["phone"] =
                          el.phone.split("-")[0] + "-" + e.target.value;
                        return newArr;
                      });
                    }}
                    style={{
                      outline: "none",
                      width: "40%",
                      borderRadius: "6px",
                      border: "1px solid #D9D9D9",
                      height: "100%",
                      // borderRadius: "6px",
                      color: "#1B1D1F",
                      fontWeight: "400",
                      fontSize: "14px",

                      fontFamily: "Poppins,sans-serif",
                    }}
                  />{" "}
                  <button
                    style={{
                      width: "34px",
                      height: "35px",
                      borderRadius: "50%",
                      background: "red",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (alertPhoneNumber.length == 1) {
                        alert("min one phone number req*");
                      } else {
                        deleteNumber(el);
                      }
                    }}
                  >
                    <p
                      style={{
                        border: "1px solid #FFFFFF",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      {" "}
                    </p>
                  </button>
                </Box>
              );
            })}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                onClick={() => {
                  addNewNumber();
                }}
                sx={{
                  // fontSize: "25px",
                  height: "25px",
                }}
              >
                {<IconAdd />}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gridGap: "10px",
              }}
            ></Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              variant="level"
              sx={{
                color: "#5D5F63",
              }}
            >
              {" "}
              Gender
            </Typography>
            <Box
              sx={{
                display: "grid",
                width: "50%",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
                justifyContent: "center",
                alignContent: "center",
                gridGap: "10px",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                name="gender"
                value={"1"}
                onChange={(e) => {
                  if (e.target.value && defaultState) {
                    let tempArr = [];
                    let tempdata = male;
                    // console.log(tempdata);
                    console.log(male);
                    for (let key in tempdata) {
                      let obj = { time: key, ...tempdata[key] };
                      tempArr.push(obj);
                    }
                    setTimeArr(tempArr);
                  }
                  setGenderState(e.target.value);
                }}
                checked={genderState == "1"}
                // placeholder="Enter an alert phone number"
                style={{
                  outline: "none",
                  width: "25px",
                  border: "1px solid #D9D9D9",
                  height: "37px",
                  borderRadius: "6px",
                  color: "#1B1D1F",
                  fontWeight: "400",
                  fontSize: "14px",
                  padding: "2px 8px",
                  fontFamily: "Poppins,sans-serif",
                }}
              />
              <Typography> Male</Typography>

              <input
                type="radio"
                name="gender"
                value={"0"}
                checked={genderState == "0"}
                onChange={(e) => {
                  setGenderState("0");
                  if (e.target.value && defaultState) {
                    let tempArr = [];
                    let tempdata = female;
                    // console.log(male);
                    for (let key in tempdata) {
                      let obj = { time: key, ...tempdata[key] };
                      tempArr.push(obj);
                    }
                    setTimeArr(tempArr);
                  }
                }}
                placeholder="Enter an alert phone number"
                style={{
                  outline: "none",
                  width: "25px",
                  border: "1px solid #D9D9D9",
                  height: "37px",
                  borderRadius: "6px",
                  color: "#1B1D1F",
                  fontWeight: "400",
                  fontSize: "14px",
                  padding: "2px 8px",
                  fontFamily: "Poppins,sans-serif",
                }}
              />
              <Typography> Female</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "110px",
            }}
          >
            <Typography
              variant="level"
              sx={{
                color: "#5D5F63",
              }}
            >
              {" "}
              Setting
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <input
                type="checkbox"
                checked={defaultState}
                onChange={(e) => {
                  console.log(e.target.checked);
                  if (e.target.checked && genderState == "1") {
                    let tempArr = [];
                    let tempdata = male;
                    for (let key in tempdata) {
                      let obj = { time: key, ...tempdata[key] };
                      tempArr.push(obj);
                    }
                    setTimeArr(tempArr);
                    setExtraStateForTime(tempArr);
                  }
                  if (e.target.checked && genderState != "1") {
                    let tempArr = [];
                    let tempdata = female;
                    // console.log(tempdata);
                    console.log(female);
                    for (let key in tempdata) {
                      let obj = { time: key, ...tempdata[key] };
                      tempArr.push(obj);
                    }
                    setTimeArr(tempArr);
                    setExtraStateForTime(tempArr);
                  }

                  if (!e.target.checked) {
                    setTimeArr(extraStateForTime);
                  }

                  setDefaultState(e.target.checked);
                }}
                placeholder="Eg:2"
                style={{
                  outline: "none",
                  width: "100%",
                  border: "1px solid #D9D9D9",
                  height: "37px",
                  borderRadius: "6px",
                  color: "#1B1D1F",
                  fontWeight: "400",
                  fontSize: "14px",
                  padding: "2px 8px",
                  fontFamily: "Poppins,sans-serif",
                }}
              />
              <Typography> Default</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#FFFFFF",
            padding: "18px",
            borderRadius: "6px",
          }}
        >
          <Box>
            <TimeModal
              open={timeBoxPopUp}
              onClose={() => {
                setTimeEditData({});
                setTimeBoxPopUp(false);
              }}
              timeEditData={timeEditData}
              setTimeArr={(e) => {
                if (loda.isEqual(e, male) && genderState == "1") {
                  setDefaultState(true);
                } else if (loda.isEqual(e, female) && genderState == "0") {
                  setDefaultState(true);
                } else {
                  setDefaultState(false);
                }
                setTimeArr(e);
              }}
              timeArr={timeArr}
            />
          </Box>
          <Typography
            variant="level"
            sx={{
              color: "#5D5F63",
            }}
          >
            {" "}
            Repeats on
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (timeArr.length >= 30) {
                  alert("Maximum Limit Reached only 30 Schedules are allowed");
                  return;
                }
                if (defaultState) {
                  setDefaultState(false);
                }
                setTimeBoxPopUp(true);
              }}
            >
              Add new
            </Button>
          </Box>
          <Box className="flex flex-row justify-between  p-2 my-4 bg-blue-500  rounded-full align-middle">
            <Typography className="p-2 text-">Disable all </Typography>
            <Switch
              // {localState[locationData.esp_id + "/" + initMap?.time_left]}
              checked={timeArr.find((el) => el.status == true) ? false : true}
              onClick={(e) => {
                let re = timeArr.find((el) => el.status == true) ? false : true;
                setTimeArr(
                  timeArr.map((el) => {
                    return {
                      ...el,
                      status: re,
                    };
                  })
                );
                setDefaultState(false);
              }}
              name="loading"
              color="primary"
            />
          </Box>
          <Box
            sx={{
              // border: "2px solid #CECFD2",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              borderRadius: "6px",
              padding: "5px",
              mt: "10px",
            }}
          >
            {timeArr
              ?.sort((a, b) => {
                const timeA = a.time.split("T")[0];
                const timeB = b.time.split("T")[0];
                return timeA.localeCompare(timeB);
              })
              .map((el) => (
                <TimeBox
                  data={el}
                  setTimeBoxPopUp={setTimeBoxPopUp}
                  setTimeEditData={setTimeEditData}
                  setTimeArr={(e) => {
                    setTimeArr(e);
                    alert("Schedule saved successfully");
                    setDefaultState(false);
                  }}
                  timeArr={timeArr}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}
