import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const navigate = useNavigate()
  useEffect(() => {
    let ln = localStorage.getItem("loggedin")
    if(ln!= null){
      navigate("/users")
    }
  }, [navigate]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box>
        <Box>
          <TextField
            value={Email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            label="Email"
            variant="outlined"
          ></TextField>
        </Box>
        <Box sx={{ mt: 1 }}>
          <TextField
            value={Password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            label="Password"
            variant="outlined"
            type="password"
          ></TextField>
        </Box>

        <Button onClick={()=>{
            if (Email == "admin@admin.com" && Password == "admin" ){
                navigate("/users")
                localStorage.setItem("loggedin",true)
            }else{
              alert("invalid credentials")
            }
        }} sx={{ width: "100%", marginTop: 1 }}>Login</Button>
      </Box>
    </Box>
  );
};
