import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import FilterSubCom from "./FilterSubCom";
import { useLocation, useNavigate } from "react-router-dom";

export default function FilterController() {
  const navigate = useNavigate();
  const location = useLocation();
  const [Enabled, setEnabled] = useState(location.state[location.state.esp_id + "/" + "enabled"] != 1);
  useEffect(() => {
    let ln = localStorage.getItem("loggedin")
    if(ln == null){
      navigate("/")
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          color: (theme) => theme.palette.primary.text,
          mt: "15px",
          fontWeight: "600",
          fontSize: "20px",
        }}
      >
           <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/users");
          }}
        >
          Users{" "}
        </span>{" "}
        {">"}
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/home",{state:location.state.user});
          }}
        >
          Available devices{" "}
        </span>{" "}
        {">"}
        {location.state?.name ? location.state?.name : location.state?.esp_id}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: "30px",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#1E1F21",
              fontWeight: "600",
              fontSize: "24px",
            }}
          >
            {" "}
            Filter controller
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={() => {
              if(Enabled){
                navigate("/home/filter/schedule", { state: location.state });
              }
            }}
          >
            {" "}
            <Typography variant="button"> {"Schedule"}</Typography>
          </Button>
        </Box>
      </Box>

      <FilterSubCom locationData={location.state} setEnabled={setEnabled} />
    </>
  );
}
